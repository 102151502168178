@use 'variables' as *;

#hero {
	background-repeat: no-repeat;
	background-size: contain;
	min-height: 900px;
	display: flex;
	flex-direction: column;
	justify-content: space-between; 
	background-position: center;
	margin: 0 auto;
	position: relative;
	@media screen and (max-width: 1210px) {
		background-size: cover;
		.container {
			padding: 0px;
		}
	}
	&.subpage {
		min-height: 580px;
		position: relative;
		margin-top: 0;
		@media screen and (max-width: 1210px) {
			min-height: 700px;
			background-position: bottom center;
		}
		h1 {
			font-weight: 500
		}
		&.thank-you, &.error {
			margin-top: 100px;
			@media screen and (max-width: 1210px) {
				min-height: 500px!important;
			}
		}
		.hero-box {
			max-width: 830px!important;
		}
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(90deg, rgba(0, 87, 79, 0.25) 0%, rgba(255, 208, 0, 0.25) 100%);
			mix-blend-mode: multiply;
			z-index: -1;
		}
	}
	&.sitemap {
		.hero-box {
			max-width: 90% !important;
		}
	}
	&.sign-up {
		.hero-box {
			max-width: 1200px!important;
		}
	}
	h1 {
			color: $white;
			font-size: 50px;
			line-height: 1.08em;
			margin-bottom: 20px;
			font-family: "Roboto Condensed", sans-serif;
			@media screen and (max-width: 1210px) {
				font-size: 40px;
				line-height: 1.1em;
				margin: 0px;
			}
			span {
					color: $yellow;
			}
	}
	.hero-box {
		padding-top: 95px;
		max-width: 930px;
		border-radius: 0px 0px 32px 0px;
		padding: 25px 30px 35px 0;
		position: relative;
		top: 130px;
		left: 0px;
		p {
			margin-bottom: 20px;
			&:first-of-type {
				margin-top: 0;
				@media screen and (max-width: 1210px) {
					margin-top: 15px;
					margin-bottom: 0;
				}
			}
		}
		@media screen and (max-width: 1210px) {
			padding: 20px;
			margin-right: 20px;
			top: 30px;
			p {
				font-size: 16px!important;
				line-height: 1.125em!important;
			}
		}
		&::before {
			content: "";
			display: block;
			height: 100%;
			width: 100vw;
			top: 0;
			left: calc(-100vw + 1px);
			position: absolute;
			background-color: $orange;
		}
	}
	.eyebrow {
			color: $white;
			border-radius: 0px 0px 16px 0px;
			padding: 10px 25px 10px 20px;
			@media screen and (max-width: 1210px) {
				margin-bottom: 15px;
			}
	}
	.subhead {
			color: $white;
	}
	&.orange, &.green, &.yellow, &.purple {
			.eyebrow {
					width: max-content;
			}
	}

	&.orange {
			.hero-box {
					background-color: $orange;
					&::before {
						background-color: $orange;
					}
			}
			.eyebrow {
					background-color: $purple;
			}
	}

	&.green {
			.hero-box {
					background-color: $green;
					&::before {
						background-color: $green;
					}
			}
			.eyebrow {
					background-color: $orange;
			}
	}

	&.yellow {
			.hero-box {
					background-color: $yellow;
					&::before {
						background-color: $yellow;
					}
					h1, p {
						color: $dark-green
					}
			}
			.eyebrow {
					background-color: $green;
					color: $white;
			}
	}

	&.purple {
		.hero-box {
				background-color: $purple;
				&::before {
					background-color: $purple;
				}
		}
		.eyebrow {
				background-color: $yellow;
				color: $purple;
		}
	}

	&.home {
			background-image: url(../img/hero/hero-0.0-index.jpg);
			border-radius: 0 0 0 32px;
			margin-left: 40px;
			background-size: cover;
			min-height: 1100px;
			@media screen and (max-width: 1210px) {
				background-image: url(../img/hero/hero-1.1-index-mobile.jpg);
				min-height: 780px;
			}
			@media screen and (min-width: 1740px) {
				background-size: contain;
			}
			.container {
				padding-left: 0;
			}
		@media screen and (max-width: 1210px) {
				margin-left: 0px;
			}
			h1 {
					font-size: 72px;
					font-style: normal;
					font-weight: 500;
					line-height: 78px; 
					@media screen and (max-width: 1210px) {
						font-size: 40px;
						line-height: 1.1em;
					}
			}
			.eyebrow {
					display: none;
			}
			.hero-box {
					max-width: 800px;
					p {
							font-size: 24px;
							font-style: normal;
							font-weight: 600;
							line-height: 28px;
							font-family: "Roboto Condensed", sans-serif;
							@media screen and (max-width: 1210px) {
								font-size: 20px!important;
								line-height: 1.2em!important;
							}
					}
					@media screen and (max-width: 1210px) {
						a.button { 
							margin-top: 20px;
						}
					}
			}
	}
	&.epidemiology {
		background-image: url(../img/hero/hero-1.1-epidemiology.png);
		@media screen and (max-width: 1210px) {
			background-image: url(../img/hero/hero-1.1-index-mobile.jpg);
		}
	}
	&.treating-all {
		background-image: url(../img/hero/hero-1.2-treating-all.png);
		min-height: 800px;
		@media screen and (max-width: 1210px) {
			min-height: 750px;
			background-image: url(../img/hero/hero-1.2-treating-all-mobile.jpg);
		}
	}
	&.mitigating-reactions {
		background-image: url(../img/hero/hero-2.1-mitigating_reactions.png);
		@media screen and (max-width: 1210px) {
			background-image: url(../img/hero/hero-2.1-mitigating_reactions-mobile.jpg);
		}
	}
	&.hypersensitivity {
		background-image: url(../img/hero/hero-2.2-hypersensitivity.png);
		@media screen and (max-width: 1210px) {
			background-image: url(../img/hero/hero-2.2-hypersensitivity-mobile.jpg);
		}
	}
	&.clinical-uses {
		background-image: url(../img/hero/hero-3.1-clinical-use.png);
		@media screen and (max-width: 1210px) {
			background-image: url(../img/hero/hero-3.1-clinical-use-mobile.jpg);
		}
	}
	&.tdm {
		background-image: url(../img/hero/hero-3.2-TDM-practice.png);
		@media screen and (max-width: 1210px) {
			background-image: url(../img/hero/hero-3.2-TDM-practice-mobile.jpg);
		}
	}
	&.rechallenging, &.desensitizing {
		background-image: url(../img/hero/hero-4.1-r-vs-s.png);
		@media screen and (max-width: 1210px) {
			background-image: url(../img/hero/hero-4.1-r-vs-s-mobile.jpg);
		}
	}
	&.stc {
		background-image: url(../img/hero/hero-5.1-stc-kit.png);
		@media screen and (max-width: 1210px) {
			background-image: url(../img/hero/hero-5.1-stc-kit-mobile.jpg);
		}
	}
	&.atr {
		background-image: url(../img/hero/hero-5.2-atr.png);
		@media screen and (max-width: 1210px) {
			min-height: 910px;
			background-image: url(../img/hero/hero-5.2-atr-mobile.jpg);
		}
	}
	.hero-callout {
		display: flex;
		background-color: rgba(0, 87, 79, 0.75);
		text-align: center;
		color: $white;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 0 0 0 32px;
		margin: 0 0 0 auto;
		position: absolute;
		width: 100%;
		bottom: 0px;

		@media screen and (min-width: 1900px) {
			max-width: 1750px;
			transform: translateX(-115px);
		}

		@media screen and (max-width: 1210px) {
			border-radius: 0;
		}
		p {
			font-family: 'Roboto Condensed', sans-serif;
			color: $white;
			font-size: 19px;
			font-style: normal;
			font-weight: 500;
			line-height: 23px;
			color: $white;
		}
		a.button {
			padding: 12px 35px 12px 20px;
			font-size: 12px;
			@media screen and (max-width: 1210px) {
				text-align: center;
			}
			&::after {
				right: 12px;
			}
		}
		.column {
			&:first-of-type {
				width: 55%;
				flex: 55%;
				text-align: right;
				@media screen and (max-width: 1210px) {
					text-align: left;
				}
			}
			justify-content: center;
			align-items: center;
			&:last-of-type {
				width: 20%;
				text-align: left;
				align-self: center;
				@media screen and (max-width: 1210px) {
					text-align: center;
				}
			}
		}
	}
}

.icon-wrap {
	display: flex;
	justify-content: center;
	align-items:center;
	max-width: 1000px;
	gap: 50px;
	position: absolute;
	bottom: 90px;
	left: calc(50% - 500px);
	&.desktop {
		@media screen and (max-width: 1210px) {
			display: none;
		}
	}
	&.mobile {
		display: none;
		@media screen and (max-width: 1210px) {
			position: unset;
			display: block;
			margin-top: 140px;
		}
	}
	.icon {
		width: 215px;
		height: 250px;
		border-radius: 0px 32px 0px 0px;
		background-color: $white;
		padding: 40px 14px 20px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: end;
		@media screen and (max-width: 1210px) {
			width: calc(100% - 36px);
			padding: 20px 14px 20px;
			height: 220px;
			margin-bottom: 100px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		p {
				font-family: "Roboto Condensed", sans-serif;
				font-size: 19px;
				line-height: 23px;
				@media screen and (max-width: 1210px) {
					text-align: center;
				}
		}
		a.button {
			padding: 16px 50px 16px 30px;
			font-size: 16px;
			line-height: 18px;
			margin-bottom: 10px;
			height: 25px;
			&::after {
				right: 20px;
			}
		}
		&.orange, &.yellow, &.purple, &.dark-green {
			&::before {
				content: "";
				position: absolute;
				top: -80px;
				left: calc(50% - 62.5px);
				display: block;
				width: 65px;
				height: 65px;
				padding: 20px;
				border-radius: 50%;
				background-repeat: no-repeat;
				background-size: 80px;
				background-position: center;
				border: 10px solid black;
				background-color: #fff;
			}
			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				height: 18px;
				width: calc(100% + 15px);
				border-radius: 0px 32px 0px 0px;
				@media screen and (max-width: 1210px) {
					display: none;
				}
			}
		}
		&.orange {
			&::before {
				background-image: url(../img/icons/droplet.svg);
				border-color: $orange;
			}
			&::after {
				background-color: $orange;
			}
		}
		&.yellow {
			&::before {
				background-image: url(../img/icons/hazard.svg);
				border-color: $yellow;
			}
			&::after {
				background-color: $yellow;
			}
		}
		&.purple {
			&::before {
				background-image: url(../img/icons/icon_vile.svg);
				border-color: $purple;
			}
			&::after {
				background-color: $purple;
			}
		}
		&.dark-green {
			&::before {
				background-image: url(../img/icons/dropper.svg);
				border-color: $dark-green;
				background-size: 50px;
			}
			&::after {
				background-color: $dark-green;
			}
		}
	}
}