@use 'variables'as *;

.table-wrapper {
    width: max-content;
    background-color: #FFFFFF;
    padding: 4px;
    border-radius: 0px 16px 0px 16px !important;
    margin: 0px auto;
    &.mobile {
        display: none;
    }
    @media screen and (max-width: 1210px) {
        &.desktop {
            display: none;
        }
        &.mobile {
            display: block;
        }
    }
}

table {
    max-width: 1140px;
    margin: 0px auto;
    font-size: 16px;
    border-radius: 0px 16px 0px 16px;
    overflow: hidden;
    border-collapse: collapse;
    tr {
        td {
            ul {
                margin-top: 0!important;
                li {
                    font-size: 16px!important;
                    line-height: 20px!important;
                    margin-bottom: 5px!important;
                    @media screen and (max-width: 1210px) {
                        font-size: 12px!important;
                        line-height: 18px!important; /* 150% */
                        letter-spacing: 0.5px!important;
                    }
                }
            }
            &.center {
                text-align: center;
            }
        }
        &:last-of-type {
            td {
                border-bottom: none!important
            }
        }
    }
}

th, td {
    padding: 8px 6px;
}

th {
    background-color: $purple;
    color: #FFFFFF;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    border: 1px solid #FFFFFF;
    &.normal {
        font-weight: 400;
    }
}

td {
    border: 1px solid $orange;
    vertical-align: top;
}

td:last-of-type {
    border-right: none;
}

tr:nth-child(odd) {
    background-color: #F7EDE8;
}
tr:nth-child(even) {
    background-color: #F0DDD1;
}

table ul {
    margin: 0px;
    padding-left: 20px;
}

table li {
    margin-bottom: 5px;
}

table li::marker {
    color: $orange;
}

.cell-white {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.cell-head-red {
    background-color: $orange;
    color: #FFFFFF;
    text-align: center;
    border: 1px solid #FFFFFF;
    vertical-align: middle;
}

.text-center {
    text-align: center;
    vertical-align: middle;
}

.row-alergic th {
    background-color: #717272;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.row-alergic td {
    background-color: #ECECEC;
    border: 1px solid #717272;
    &:last-of-type {
        border-right: none;
    }
}

#table-epidemiology {
    max-width: 1100px;
    margin-bottom: 60px;
    position: absolute;
    width: 100%;
    left: 20px;
    left: calc(50% - 550px);
    top: 70px;
    padding: 5px;
    @media screen and (min-width: 1211px) {
        .mobile {
            display: none;
        }
    }
    @media screen and (max-width: 1210px) {
        max-width: 354px;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
        top: 150px;
        background: $white;
        background-color: $white;
        tbody { background: $white; }
        .mobile {
            tr, td {
                background-color: $white;
            }
        }
        .desktop {
            display: none;
        }
    }
    tr:first-of-type {
        th:last-of-type {
            border-radius: 0px 20px 0 0;
            @media screen and (max-width: 1210px) {
                border-radius: 0px 12px 0 0;
            }
        }
    }
    tbody { border: 5px solid $white }
    th {
        background-color: $green;
        height: 55px;
    }
    tr:nth-child(odd) {
        background-color: #F0DDD1;
    }
    tr:nth-child(even) {
        background-color: #F7EDE8;
    }
}

@media screen and (max-width: 1210px) {
    .table-wrapper.mobile {
        width: calc(100% + 24px);
        position: relative;
        left: -18px;
        &.anaphylaxis, &.infusion {
            padding-top: 30px;
            padding-bottom: 30px;
            background-color: $purple;
            left: -22px;
            .tabs {
                &:last-of-type {
                    h4 { background-color: #717272;  border-radius: 0 0 32px 0; }
                }
                &:first-of-type {
                    h4 { background-color: #3E2138; } 
                }
            }
        }
    }
    .tabs {
        max-width: 100%;
        &:first-of-type {
            border-radius: 0px 15px 0 0px!important;
        }
        &:last-of-type {
            border-radius: 0px 0 0 32px!important;
            .tab {
                padding-bottom: 20px;
                border-radius: 0 0 32px 0px;
                h4 {
                    border-radius: 0 0 0 3px;
                }
            }
        }
        background: $purple;
        .subheader {
            font-size: 12px;
            font-style: normal;
            font-weight: 900;
            line-height: 14px; /* 116.667% */
            color: $white;
            padding: 20px 20px 5px;
        }
        .header {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; /* 100% */
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $white;
            padding: 5px 20px 20px;
        }
        .tab {
            max-width: 95%;
            margin: 0;
            background: $purple;
            padding-bottom: 10px;
            border-radius: 0 !important;
            position: relative;
            &::after {
                content: "+";
                position: absolute;
                right: 20px;
                top: 20px;
                color: $white;
                font-size: 36px;
                z-index: 9;
            }
            h4 {
                border: 2px solid #FFF;
                border-left: none;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; 
                color: $white;
                background-color: $orange;
                display: block;
                width: 100%;
                width: calc(100% - 50px);
                border-radius: 0px 0px 32px 0px;
                padding: 20px 25px;
                margin: 0;
            }
            .tab-content {
                display: none;
                border-radius: 0px 32px 0px 0px;
                border: 2px solid #FFF;
                background: #F7EDE8;
                padding-right: 20px;
                h5 {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px; /* 100% */
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-bottom: 11px;
                    &.normal {
                        text-transform: unset;
                    }
                }
                ul {
                    padding-left: 35px;
                    margin: 0;
                    li {
                        font-size: 18px;
                        font-style: normal;
                        line-height: 22px; 
                        &::marker {
                            color: $orange;
                        }
                    }
                }
                p {
                    font-size: 18px;
                    font-style: normal;
                    line-height: 22px; 
                    padding-left: 18px;
                    padding-right: 18px;
                }
            }
            &.opened {
                &::after {
                    content: "-";
                }
                .tab-content {
                    display: block;
                    border-radius: 0;
                    position: relative;
                    z-index: 1;
                    &::before {
                        content: "";
                        background-color: #F7EDE8;
                        position: absolute;
                        top: -50px;
                        right: -2px;
                        height: 50px;
                        width: 50px;
                    }
                }
                h4 {
                    position: relative;
                    z-index: 2;
                }
            }
        }
        .sublist-wrap {
            background-color: #fff;
            margin: 0;
            padding: 10px 0;
            border-radius: 0;
            p.with-link {
                a {
                    display: inline-block;
                    text-decoration: underline;
                    padding-left: 0;
                    margin-left: 10px;
                }
            }
            .sublist {
                li {
                    color: $dark-green;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; 
                }
            }
        }
    }
}