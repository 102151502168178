@use 'variables' as *;

footer {
    color: $white;
    font-size: 14px;
    box-sizing: border-box;
    margin: 0 auto 0px;
    border-radius: 55px 0px 0px 0px;
    padding: 60px 40px 60px 40px;
    position: relative;
    .nav-wrap {
        display: inline;
    }
    @media screen and (max-width: 1210px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0px;
        padding: 80px 15px 60px 15px;
        .container {
            padding-left: 0;
            .footer-nav {
                margin: 0 auto;
                max-width: 300px;
                text-align: center;
                .nav-wrap {
                    display: block;
                    position: relative;
                    left: -10px;
                }
            }
        }
    }
    a { 
        color: $white;
        font-weight: 600; 
        position: relative;
        @media screen and (max-width: 1210px) {
            margin-bottom: 20px;
            display: inline-block;
        }
    }
    .footer_pipe { 
        margin: 0px 22px;
        @media screen and (max-width: 1210px) {
            margin: 0 10px;
        }
        &:nth-of-type(2) {
            @media screen and (max-width: 1210px) {
                display: none;
            }
        }
    }
    &::before {
        content: "";
        width: 100%;
        position: absolute;
        left: 50%;
        z-index: -1;
        top: 0;
        bottom: 0;
    }
    &.orange {
        &::before {
            background-color: $orange;
        }
    }
    &.green {
        &::before {
            background-color: $green;
        }
    }
    &.dark-green {
        &::before {
            background-color: $dark-green;
        }
    }
    &.yellow {
        &::before {
            background-color: $yellow;
        }
    }
    &.purple {
        &::before {
            background-color: $purple;
        }
    }
    .reverse_stack { display: flex; justify-content: space-between;
        @media screen and (max-width: 1210px) {
            text-align: center;
            flex-direction: column-reverse;
        }
    }
    .footer-link img { width: 287px; margin-right: 20px; }
    &.orange {
        background-color: $orange;
    }
    &.green {
        background-color: $green;
    }
    &.dark-green {
        background-color: $dark-green;
    }
    &.yellow {
        background-color: $yellow;
        p, a, .footer_pipe {
            color: $dark-green;
        }
    }
    &.purple {
        background-color: $purple;
    }
}

.footer-cta {
    display: flex;
    justify-content: right;
    position: relative;
    right: 0px;
    margin-top: 150px;
    &.no-content {
        margin-top: 80px;
    }
    a.button {
        &::after {
            right: 30px;
        }
    }
    &.rechallenging {
        margin-top: 0;
        @media screen and (max-width: 1210px) {
            margin-top: 60px;
        }
        &::after {
            height: 650px!important;
            bottom: 0!important;
        }
    }
    &.stc {
        margin-top: 0;
        &::after {
            height: 600px!important;
        }
    }
    &.desensitizing {
        margin-top: 0;
        &::after {
            height: 600px!important;
            bottom: 0!important;
            @media screen and (max-width: 1210px) {
                height: 820px!important;
            }
        }
    }
    &.tdm {
        margin-top: 0;
        &::after {
            height: 1000px!important;
            bottom: 0!important;
        }
    }
    &.clinical {
        margin-top: 0;
        @media screen and (max-width: 1210px) {
            margin-top: 100px;
        }
        &::after {
            height: 1000px!important;
            bottom: 0!important;
        }
    }
    &::after {
        content: "";
        background: rgb(0,87,79);
        background: linear-gradient(90deg, #bfd5d3, #fff3c0 100% );
        display: block;
        width: 100vw;
        height: 436px;
        border-radius: 55px 0px 0px 0px;
        position: absolute;
        left: 5px;
        bottom: 0;
        z-index: -1;
        @media screen and (min-width: 1625px) {
            width: 200vw;
            left: -100vw;
        }
    }
    &.full-width {
        &::after {
            content: "";
            width: 200vw;
            left: -100vw;
            bottom: -50px;
        }
    }
    &.no-footer-mask {
        @media screen and (max-width: 1210px) {
            margin-top: 250px;
        }
        &::after {
            bottom: 0;
        }
    }
    .column {
        text-align: right;
        &:last-of-type {
            padding-right: 0;
        }
        a.button {
            font-family: "Roboto Condensed", sans-serif;
            display: inline-flex;
            height: 100px;
            padding: 0 62px;
            justify-content: flex-end;
            align-items: center;
            gap: 17px;
            flex-shrink: 0;
            color: $white;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            border-radius: 40px 0px 0px 0px;
            margin-right: 0px;
            padding-right: 80px;
            @media screen and (max-width: 1210px) {
                display: flex;
                text-align: left;
                padding: 0 62px 0 20px;
                &::after {
                    transform: scale(1.5), rotate(-90deg);
                }
            }
            &::after {
                right: 50px;
                @media screen and (max-width: 1210px) {
                    right: 30px
                }
            }
            &.orange {
                background-color: $orange;
            }
            &.green {
                background-color: $green;
            }
            &.yellow {
                background-color: $yellow;
                color: $dark-green;
            }
            &.purple {
                background-color: $purple;
            }
        }
    }
}