@use 'variables' as *;

header {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 8px solid $orange;
    background-color: $white;
    z-index: 99;
    @media screen and (max-width: 1210px) {
        height: unset;
        min-height: 126px;
        background-color: $white;
    }
    #container-nav {
        max-width: 1480px;
        margin: 0px auto;
        position: relative;
    }
    #label-healthcare-pro {
        background-color: $white;
        font-size: 12px;
        padding: 20px 40px;
        @media screen and (min-width: 1480px) {
            padding: 20px 0;
        }
        @media screen and (max-width: 1210px) {
            padding: 7px 7px 7px 20px;
            font-size: 10px;
            line-height: 1.2em;
            margin-right: 200px;
        }
    }
    #sign-up {
        color: $white;
        border-radius: 0px 0px 15px 15px;
        background-color: $green;
        padding: 21px 37px 21px 49px;
        margin-top: -8px;
        font-size: 14px;
        text-decoration: none;
        background-image: url(../img/shared/signup-desktop-light.svg);
        float: right;
        margin-right: 75px;
        @media screen and (max-width: 1210px) {
            border-radius: 0px 0px 0px 16px;
            padding: 15px 25px 15px 36px;
            margin-right: 0px;
            font-size: 12px;
            line-height: 1.16em;
            background-image: url(../img/shared/signup-mobile-light.svg);
            background-size: cover;
        }
    }
    #header-stc-logo {
        img {
            width: 350px;
        }
        padding: 0px 40px;
        
        @media screen and (min-width: 1480px) {
            padding: 0px 0;
        }
        @media screen and (max-width: 1210px) {
            padding: 15px 20px 0px;
            img {
                width: 205px;
            }
        }
    }

    &.orange {
        #sign-up {
            background-color: $green;
            color: $white;
        }
    }
    &.green {
        border-top: 8px solid $green;
        #sign-up {
            background-color: $yellow;
            color: $dark-green;
            background-image: url(../img/shared/signup-desktop-dark.svg);
        }
    }
    &.stc {
        #sign-up {
            background-color: $yellow;
            color: $dark-green;
            background-image: url(../img/shared/signup-desktop-dark.svg);
        }
    }
    &.yellow {
        border-top: 8px solid $yellow;
        #sign-up {
            background-color: $purple;
            color: $white;
        }
    }
    &.purple {
        border-top: 8px solid $purple;
        #sign-up {
            background-color: $orange;
            color: $white;
        }
    }
}