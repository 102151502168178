@use 'variables'as *;

.sign-up #hero {
    min-height: 700px!important;
    background-color: red;
    .hope-box {
        top: 0px;
    }
}
.sign-up #hero.subpage::before {
    background-image: linear-gradient(90deg, #bfd5d3, #fff3c0 100% )!important;
}

.sign-up #main {
    margin-top: -110px!important;
    padding-bottom: 0px!important;
}

form {
    margin: 0px;
    max-width: 1010px;
    text-align: left;
    .row { 
        margin-top: 0px;
        margin-bottom: 30px;
        text-align: left!important;
        .column .row {
            margin-bottom: 0px;
        }
    }
    .width-50per {
        width: 50%;
    }
    .width-25per {
        width: 25%;
    }

    select, input[type=text], input[type=select], input[type=email] {
        max-width: 470px;
        width: 100%;
        padding: 8px 8px 6px;
        border: 1px solid #707070;
        color: #636466;
        background: #fff;
        border-radius: 0;
        height: 36px;
        margin-bottom: 5px;
        border: 2px solid #00A39A;
        box-sizing: content-box;
        @media screen and (max-width: 1210px) {
            max-width: calc(100% - 38px)!important;
        }

        &:active, &:visited {
            border: 2px solid $dark-green;
        }

        &:focus {
            border: 2px solid #00A39A;
        }
    }

    label {
        display: block;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        color: #FFFFFF;
        text-align: left;
        span {
            font-weight: normal;
        }
    }

    input.small {
        width: 235px;
    }

    .custom-select {
        &.selected {
            font-style: normal;
        }
    }

    .required_text {
        font-size: 14px;
        margin: 0px 0 15px;
        text-align: left;
        color: #FFFFFF;
    }

    .opt-in, .opt-in a {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 22px;
        margin-top: 20px;
        padding-bottom: 30px;
    }

    .required_asterisk, .pristine-error {
        color: #FFA69B;
    }

    .not-active {
        display: none;
    }
    #phone-number, #zipCode {
        width: 210px;
    }
}

.has-danger .not-active {
    display: inline;
}

.pristine-error {
    color: red;
    font-size: 16px;
    line-height: 21px;
    font-style: italic;
}

.has-danger input,
.has-danger select {
    border: 2px solid red;
}

.has-danger {
    input[type="checkbox"] {
        &::before {
            content: "";
            display: block;
            width: 13px;
            height: 13px;
            border: 1px solid red;
            border-radius: 3px;
        }
    }
}

.pristine-error {
    font-size: 16px;
    line-height: 21px;
}

select {
    appearance: none;
    background-image: url("../img/shared/arrow-down-orange.svg")!important;
    background-size: 20px 14px;
    background-repeat: no-repeat;
    background-position: right center!important;
    background-position-x: 98%!important;
    font-size: 16px;
    font-style: italic;
    color: $dark-green;
    max-width: 500px;
    border: solid 1px $dark-green;
    background-repeat: no-repeat!important;

    &:focus {
        background-image: url("../img/shared/arrow-down-orange.svg")!important;
        background-repeat: no-repeat;
        background-position: right center!important;
        background-position-x: 98%!important;
    }

    &:active {
        background-image: url("../img/content/arrow-up-orange.svg")!important;
        background-repeat: no-repeat;
        background-position: right center!important;
        background-position-x: 98%!important;
    }

    &.selected {
        color: $dark-green;
    }
}

.checkbox {
    display: inline-block;
    width: 200px;
    height: 25px;

    label, input {
        display: inline;
    }

    label {
        font-weight: normal;
    }

    input {
        &:last-of-type {
            margin-left: 40px;
        }
    }
}

form button {
    border: none;
    border-radius: 80px;
    background: #FFD000;
    color: #2C3830;
    display: inline-flex;
    padding: 21px 40px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        right: 15px;
        top: 15px;
        top: calc(50% - 6px);
        width: 12px;
        height: 12px;
        background-image: url(../img/shared/arrow-down-green.svg);
        background-repeat: no-repeat;
        transform: rotate(-90deg);
    }
}

.btn-hodler {
    @media screen and (max-width: 1210px) {
        margin: 30px auto 0 !important;
    }
}