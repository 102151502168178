@use 'variables' as *;

.subpage-callout {
  width: calc(100% - 160px);
  display: block;
  position: relative;
  top: -40px;
  border-radius: 0px 0px 32px 0;
  margin-top: 150px;
  padding-top: 75px;
  padding-bottom: 60px;
  img.mobile {
    display: none;
    max-width: 100%;
  }
  img.desktop {
    max-width: 100%;
  }
  @media screen and (max-width: 1210px) {
    img.mobile {
      display: block;
    }

    img.desktop {
      display: none;
    }
    width: 100%;
    width: calc(100% - 36px);
    padding-top: 140px;
    margin-top: 140px;
  }
  .subpage-footer-cta {
    font-size: 16px;
  }
  ul {
    margin-left: 0;
    padding-left: 20px;
    margin-top: 25px;
    li {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
  &.treating {
    padding-top: 175px;
    padding-bottom: 220px;
    &.asp {
      &.subpage-callout {
        @media screen and (max-width: 1210px) {
          padding-top: 300px;
          padding-bottom: 300px;
        }
      }
      .subpage-footer-cta {
        p {
          text-align: left!important;
        }
        left: 18px;
        width: calc(100% - 80px);
        bottom: -100px;
        @media screen and (max-width: 1210px) {
          border-radius: 0 0 0 32px!important;
          bottom: -160px;
          width: calc(100% - 88px);
          left: 20px;
        }
      }
    }
    @media screen and (max-width: 1210px) {
      padding-bottom: 40px;
    }
    h3.dark-green {
      min-height: 56px;
      @media screen and ( max-width: 768px) {
        min-height: unset;
      }
    }
    .subpage-content {
      h3.title {
        margin-top: 120px;
      }
    }
    .subpage-footer-cta {
      right: unset;
      left: calc(50% - 600px);
      border-radius: 0 16px 0 0!important;
      bottom: 0;
      p {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 24px;
        line-height: 28px;
        font-weight: 300;
        margin-top: 0;
      }
      .column {
        p {
          font-weight: 600;
        }
      }
    }
    ul {
      padding-left: 40px;
    }
  }
  &.age-at {
    .subpage-content {
      h3 {
        margin-top: 40px;
        margin-bottom: 120px;
        @media screen and ( max-width: 768px) {
          margin-top: 40px;
          margin-bottom: 200px;
        }
      }

      .big-number-wrap .column:nth-of-type(2) {
        @media screen and ( max-width: 768px) {
          border-top: unset;
          border-bottom: 4px dashed $orange;
          margin-top: 3%;
        }
      }
      @media screen and ( max-width: 768px) {
        .big-number-wrap .column:nth-of-type(3) {
          padding-top: 0;
          padding-bottom: 30px;
        }
      }
    }
    .content-snippet { 
      padding-top: 270px; 
      @media screen and ( max-width: 768px) {
        padding-top: 370px
      }
    }
    .subpage-footer-cta {
      max-width: 870px;
      @media screen and ( max-width: 768px) {
        bottom: -102px
      }
    }
  }
  &.premedication {
    @media screen and (max-width: 1210px) {
      padding-top: 110px;
    }
    .subpage-content {
      h3 {
        text-align: center;
        margin-top: 40px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 40px;
      }
    }
    .subpage-footer-cta {
      @media screen and (max-width: 1210px) {
        bottom: -60px;
      }
      .row {
        .column:first-of-type {
          width: calc(100% - 400px);
          flex: calc(100% - 400px);
          @media screen and (max-width: 750px) {
            width: calc(100%);
            flex: calc(100%);
          }
        }
        .column:last-of-type {
          width: 400px;
          flex: 400px;
        }
      }
    }
  }
  &.avoiding {
    padding-bottom: 60px;
    .subpage-footer-cta {
      bottom: -21px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  &.treating {
    .content-snippet {
      img {
        width: 100%;
        max-width: 1180px;
        margin: 0 auto;
      }
    }
    &.treating-tdm {
      padding-top: 158px;
      @media screen and (max-width: 1210px) {
        padding-top: 340px;
        padding-bottom: 200px;
        .subpage-content {
          top: -65px;
        }
      }
      @media screen and (max-width: 1210px) {
        .subpage-footer-cta {
          left: 18px;
          width: calc(100% - 72px);
          bottom: 0;
          padding-left: 15px;
          padding-right: 15px;
          p { text-align: center!important; }
          .column {
            text-align: center;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            p {
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 26px; 
            }
            a.button {
              margin: 20px auto 0;
            }
          }
        }
      }
      .subpage-footer-cta .row .column {
        &:first-of-type {
          width: calc(100% - 450px)!important;
          flex: calc(100% - 450px)!important;
          p { text-align: left; margin-left: 2px; margin-bottom: 0; }
        }
        &:last-of-type {
          width: 450px!important;
          flex: 450px!important;
        }
      }
    }
  }
  &.grading {
    padding-bottom: 10px;
    @media screen and (max-width: 1210px) {
      padding-top: 100px;
    }
    .content-snippet {
      border-radius: 0 0 0 32px;
      @media screen and (max-width: 1210px) {
        padding-top: 50px;
      }
      h3 {
        margin-top: 0!important;
      }
      ul {
        margin-bottom: 0;
        li:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .subpage-content {
      h3 {
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
    .orange-box {
      border-radius: 0px 16px;
      background: $orange;
      max-width: 1100px;
      padding: 20px;
      margin: 40px auto 60px;
      position: relative;
      p {
        color: $white;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        font-family: "Roboto Condensed", sans-serif;
      }
      &::after {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        left: calc(50% - 25px);
        bottom: -15px;
        transform: rotate(45deg);
        background-color: $orange;
        z-index: 0;
      }
    }
    .icon-with-text {
      padding-left: 100px;
      position: relative;
      max-width: 1048px;
      margin: 60px auto 0;
      p {
        color: $white;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        max-width: 800px;
      }
      a {
        color: $white;
        text-decoration: underline;
        font-weight: 700;
      }
      &::before {
        content: "";
        background-image: url(../img/icons/app.svg);
        background-repeat: no-repeat;
        width: 61px;
        height: 85px;
        display: block;
        position: absolute;
        background-size: contain;
        left: 0;
        top: -20px;
        @media screen and (max-width: 1210px) {
          top: 0;
        }
      }
    }
    th {
      span {
        font-weight: 400;
        text-transform: none;
      }
    }
  }
  &.brochure {
    padding-top: 280px;
    padding-bottom: 200px;
    @media screen and (max-width: 1210px) {
      border-radius: 0;
      padding-bottom: 120px;
    }
    .subpage-footer-cta {
      border-radius: 0px 32px 0px 0px!important;
      right: unset;
      left: calc(50% - 462px);
      padding-left: 180px;
      width: 674px;
      border-radius: 0!important;
      bottom: 0;
      position: absolute;
      @media screen and (max-width: 1210px) {
        left: -10px;
        width: calc(100% - 160px);
        bottom: -50px;
        padding: 10px 10px 10px 140px;
      }
      p { text-align: left!important; }
      &::before {
        content: "";
        background-image: url(../img/content/2.2-booklet01.png);
        background-repeat: no-repeat;
        width: 156px;
        height: 200px;
        display: block;
        position: absolute;
        background-size: contain;
        left: 0;
        top: -30px;
        @media screen and (max-width: 1210px) {
          width: 130px;
          height: 160px;
          top: 0;
        }
      }
      .column:last-of-type {
        width: 250px;
        flex: 250px;
        a.button {
          padding: 16px 40px 16px 30px;
          &::after {
            right: 20px;
          }
        }
      }
    }
    &.tdm {
      @media screen and (max-width: 1210px) {
        padding-top: 100px;
      }
      img {
        max-width: 1123px;
      }
      .subpage-footer-cta {
        @media screen and (max-width: 1210px) {
          bottom: -100px;
        }
        &::before {
          content: "";
          background-image: url(../img/content/3.1-booklet01.png);
        }
      }
    }
  }
  &.clinical {
    display: flex;
    padding-bottom: 200px;
    @media screen and (max-width: 1210px) {
      flex-wrap: wrap;
    }
    .content-snippet {
      border-radius: 0 0 0 32px;
      @media screen and (max-width: 1210px) {
        padding-right: 40px;
      }
    }
    .column {
      width: 25%;
      flex: 25%;
      @media screen and (max-width: 1210px) {
        width: 100%;
        flex: 100%;
      }
      img {
        max-width: 100%;
      }
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding-left: 30px;
      }
    }
    .subpage-footer-cta {
      max-width: 900px;
      margin: 0 auto;
      position: absolute;
      left: calc(50% - 610px);
      bottom: 0;
      @media screen and (max-width: 1210px) {
        left: 26px;
        width: calc(100% - 80px);
      }
      .column {
        &:first-of-type {
          padding-left: 120px;
          position: relative;
          @media screen and (max-width: 1210px) {
            padding-left: 110px;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: -40px;
            top: -60px;
            background-image: url(../img/content/3.1-booklet01.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: 156px;
            height: 200px;
          }
          width: calc(100% - 300px)!important;
          flex: calc(100% - 300px)!important;
        }
        &:last-of-type {
          width: 300px!important;
          flex: 300px!important;
          text-align: right!important;
        }
      }
    }
  }
  &.saa {
    margin-top: 120px;
    .subpage-content {
      padding-top: 40px;
      padding-bottom: 200px;
      @media screen and (max-width: 1210px) {
        padding-bottom: 100px;
      }
      .content-snippet {
        border-radius: 0 0 0 32px;
        @media screen and (max-width: 1210px) {
          padding-bottom: 80px;
        }
      }
      .row {
        gap: 30px;
        margin-bottom: 50px;
        &:first-of-type {
          .column {
            @media screen and (max-width: 1210px) {
              padding-top: 60px;
            }
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .column {
        border-radius: 0px 32px;
        background: #FFEEA6;
        min-height: 280px;
        padding: 30px 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        @media screen and (max-width: 1210px) {
          padding: 30px 20px 10px;
        }
        p, strong {
          font-family: "Roboto Condensed", sans-serif;
          display: block;
          font-size: 24px;
          font-style: normal;
          line-height: 28px;
          text-align: left;
          width: 100%;
        }
      }
    }
    .subpage-footer-cta {
      border-radius: 0!important;
      position: absolute;
      bottom: 0;
      left: calc(50% - 600px);
      padding-top: 25px;
      padding-bottom: 25px;
      @media screen and (max-width: 1210px) {
        left: 16px;
        width: calc(100% - 80px);
        bottom: -80px;
        .row .column:first-of-type p {
          margin-left: 0;
        }
      }
      > p { font-weight: 400; margin-top: 0; }
      .row {
        .column {
          p { text-align: left!important; }
        }
      }
    }
  }
  &.slowing {
    padding-top: 110px;
    @media screen and (max-width: 1210px) {
      padding-top: 130px;
    }
    .content-snippet {
      border-radius: 0 0 0 32px;
      @media screen and (max-width: 1210px) {
        padding-top: 80px;
      }
    }
    img {
      max-width: 100%;
      @media screen and (max-width: 1210px) {
        margin-top: 40px;
      }
    }
  }
  &.desensitization {
    padding-top: 100px;
    img { max-width: 100%; }
    .content-snippet {
      border-radius: 0 0 0 32px;
    }
  }
  &.study {
    padding-top: 110px;
    .content-snippet {
      border-radius: 0 0 0 32px;
    }
  }
  &.nccn {
    padding-top: 150px;
    @media screen and (max-width: 1210px) {
      padding-top: 310px;
    }
    .subpage-content {
      top: -40px;
    }
    .content-snippet {
      border-radius: 0 0 0 32px;
      p {
        margin-bottom: 40px;
      }
    }
    ul {
      li {
        list-style: none;
        padding-left: 20px;
        position: relative;
        height: 33px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media screen and (max-width: 1210px) {
          height: unset;
        }
        &::before {
          content: "";
          background-image: url(../img/icons/check-mark.svg);
          background-repeat: no-repeat;
          width: 30px;
          height: 33px;
          display: block;
          position: absolute;
          left: -20px;
        }
      }
    }
  }

  &.widely {
    padding-bottom: 30px;
    margin-top: 120px;
    .content-snippet {
      justify-content: center;
      align-items: center;
      border-radius: 0 0 0 32px;
      padding-left: 60px;
      padding-right: 60px;
      @media screen and (max-width: 1210px) {
        padding-left: 30px;
        padding-right: 30px;
      }
      .column {
        padding-left: 220px;
        position: relative;
        min-height: 200px;
        @media screen and (max-width: 1210px) {
          padding-top: 220px;
          padding-left: 0;
        }
        p {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; 
          &:first-of-type {
            font-weight: 700;
          }
          &:last-of-type {
            font-weight: 400;
          }
        }
        &::before {
          content: "";
          width: 200px;
          height: 200px;
          display: block;
          position: absolute;
          left: 0;
          top: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(../img/content/3.1-SAA.png);
          @media screen and (max-width: 1210px) {
            left: calc(50% - 100px)
          }
        }
      }
    }
    .icon-with-text {
      max-width: 900px;
      margin: 0 auto 0 65px;
      text-align: center;
      color: $dark-green;
      padding-top: 50px;
      padding-left: 0px;
      position: relative;
      @media screen and (max-width: 1210px) {
        padding-top: 130px;
        margin: 0 auto;
      }
      &::before {
        content: "";
        width: 61px;
        height: 85px;
        display: block;
        position: absolute;
        left: -15px;
        top: 35px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/icons/app01.svg);
        @media screen and (max-width: 1210px) {
          left: calc(50% - 42px)
        }
      }
      p { 
        font-weight: 500;
      }
      a {
        color: $dark-green;
        font-weight: 500;
      }
    }
  }

  &.multidisciplinary {
    padding-top: 150px;
    @media screen and (max-width: 1210px) {
      padding-top: 250px;
    }
    h3 {
      margin: 40px 0;
    }
    .subpage-title {
      p { margin-bottom: 0; }
      text-align: left;
    }
    div {
      text-align: center;
    }
  }

  &.help {
    padding-top: 100px;
    @media screen and (max-width: 1210px) {
      padding-top: 180px;
    }
    .subpage-footer-cta {
      bottom: -21px;
      padding-top: 20px;
      padding-bottom: 20px;
      @media screen and (max-width: 1210px) {
        bottom: -50px;
      }
    }
    .content-snippet {
      border-radius: 0 0 0 32px;
      @media screen and (max-width: 1210px) {
        padding-top: 50px;
      }
    }
    ul {
      margin-top: 0;
    }
    .column {
      &:first-of-type {
        width: 50%;
        flex: 50%;
      }
    }
  }

  &.switching {
    .content-snippet {
      border-radius: 0 0 0 32px;
    }
    .subpage-footer-cta {
      bottom: -21px;
      padding-top: 20px;
      padding-bottom: 20px;
      @media screen and (max-width: 1210px) {
        bottom: -30px;
      }
      .column {
        &:first-of-type {
          width: calc(100% - 380px)!important;
          flex: calc(100% - 380px)!important;
        }
        &:last-of-type {
          width: 380px!important;
          flex: 380px!important;
        }
      }
    }
    ul {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  &.stc-kit, &.resources {
    padding-top: 50px;
    .row {
      gap: 50px;
      margin-top: 80px;
    }
    h3 { 
      color: $green;
      @media screen and (max-width: 1210px) {
        text-align: center;
      }
    }
    a.button {
      font-size: 16px;
    }
    .resource-icon {
      padding-left: 200px;
      position: relative;
      text-align: left;
      &::before {
        content: "";
        width: 150px;
        height: 209px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-size: contain;
        background-repeat: no-repeat;
        @media screen and (max-width: 1210px) {
          left: calc(50% - 75px);
          top: 0px;
        }
      }
      p {
        margin-top: 5px;
        @media screen and (max-width: 1210px) {
          text-align: center;
        }
      }
      &.nurses {
        @media screen and (max-width: 1210px) {
          margin-top: 60px;
        }
        &::before {
          background-image: url(../img/content/5.1-booklet01.png);
        }
      }
      &.asparaginase {
        &::before {
          background-image: url(../img/content/5.1-booklet03.png);
        }
      }
      &.therapeutic {
        &::before {
          background-image: url(../img/content/5.1-booklet02.png);
        }
      }
      &.rechallenging {
        &::before {
          background-image: url(../img/content/5.1-booklet04.png);
        }
      }
      &.implementation {
        &::before {
          background-image: url(../img/content/5.1-booklet05.png);
        }
      }
      &.paper {
        padding-left: 100px;
        @media screen and (max-width: 1210px) {
          padding-left: 18px;
          padding-right: 18px;
          padding-top: 100px;
          strong, p {
            text-align: left;
            display: block;
          }
        }
        &::before {
          width: 52.364px;
          height: 73.645px;
          background-image: url(../img/content/5.1-paper.svg);
          left: 20px;
          @media screen and (max-width: 1210px) {
            left: calc(50% - 26px);
          }
        }
      }
      @media screen and (max-width: 1210px) {
        strong { display: block; }
        a.modal-leaving {
          display: inline-block;
          margin-top: 20px;
        }
      }
    }
  }

  &.stc-kit {
    .resource-icon {
      @media screen and (max-width: 1210px) {
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 230px;
        text-align: center;
      }
    }
  }

  &.resources {
    padding-top: 100px;
    @media screen and (max-width: 1210px) {
      padding-top: 140px;
    }
    .content-snippet {
      @media screen and (max-width: 1210px) {
        padding-top: 60px;
      }
    }
    .row {
      margin-top: 50px;
      &:last-of-type {
        padding-bottom: 50px;
        @media screen and (max-width: 1210px) {
          padding-bottom: 0;
        }
      }
      &.resource-links {
        max-width: 1100px;
        margin: 0 auto;
        justify-content: left;
        text-align: left;
        @media screen and (max-width: 1210px) {
          .column {
            padding-left: 0;
            padding-right: 0;
          }
        }
        &.next {
          @media screen and (min-width: 769px) {
            margin-top: 40px;
          }
        }
        h4 {
          color: $dark-green;
          text-align: left;
          font-family: "Roboto Condensed", sans-serif;
        }
      }
    }
  }

  &.desensitization {
    padding-top: 100px;
    @media screen and (max-width: 1210px) {
      padding-top: 120px;
    }
    .content-snippet {
      padding-top: 40px;
    }
    .column {
      &:first-of-type {
        width: 400px;
        flex: 400px;
        p {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        a {
          text-align: left;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          display: inline;
        }
        span {
          display: block;
          margin-top: 25px;
        }
      }
    }
  }

  &.study {
    padding-top: 70px;
    @media screen and (max-width: 1210px) {
      padding-top: 100px;
      .study-icons {
        &:first-of-type {
          padding-top: 120px;
        }
      }
    }
    .row {
      margin-bottom: 50px;
      gap: 50px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .column {
      justify-content: center;
      align-items: center;
      display: flex;
      padding-left: 120px;
      position: relative;
      @media screen and (max-width: 1210px) {
        padding-left: 18px;
        margin-bottom: 50px;
      }
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
      }
      &::before {
        content: "";
        width: 88px;
        height: 70px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0;
        display: block;
        @media screen and (max-width: 1210px) {
          left: calc(50% - 44px);
          top: -80px;
        }
      }
      &.pill {
        &::before {
          background-image: url(../img/icons/pill.svg);
        }
      }
      &.clock {
        &::before {
          background-image: url(../img/icons/clock.svg);
        }
      }
      &.chart {
        &::before {
          background-image: url(../img/icons/chart.svg);
        }
      }
      &.dropper {
        &::before {
          height: 80px;
          width: 80px;
          background-size: contain;
          background-image: url(../img/icons/dropper02.svg);
        }
      }
    }
  }

  &.stepping {
    padding-top: 180px;
    @media screen and (max-width: 1210px) {
      padding-top: 200px;
    }
    .content-snippet {
      @media screen and (max-width: 1210px) {
        padding-top: 50px;
      }
      &:first-of-type {
        margin-bottom: 50px;
      }
    }
    .subpage-footer-cta {
      bottom: -21px;
      padding-top: 20px;
      padding-bottom: 20px;
      max-width: 850px;
      @media screen and (max-width: 1210px) {
        bottom: -50px;
      }
      .column {
        &:first-of-type {
          width: calc(100% - 410px)!important;
          flex: calc(100% - 410px)!important;
        }
        &:last-of-type {
          width: 410px!important;
          flex: 410px!important;
        }
      }
    }
  }

  hr {
    width: 1117px;
    height: 1px;
    margin: 50px auto;
    background-color: $orange;
    border: none;
  }

  &.allergic {
    padding-top: 150px;
    @media screen and (max-width: 1210px) {
      padding-top: 220px;
    }
    h3 {
      text-align: left!important;
      max-width: 1140px;
      margin: 20px auto 0;
      @media screen and ( max-width: 768px) {
        text-align: center!important;
      }
    }
    a {
      color: $purple;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-left: 20px;
      padding-bottom: 20px;
      display: inline-block;
      @media screen and (max-width: 1210px) {
        font-size: 14px;
        line-height: 18px;
        margin-right: 20px;
        &::after {
          right: 15px;
        }
      }
    }
    .content-snippet {
      padding-bottom: 10px;
      @media screen and (max-width: 1210px) {
        padding: 20px 0;
      }
    }
    p.with-link {
      a {
        position: relative;
        span { text-decoration: underline;}
        &::after {
          content: "›";
          position: relative;
          right: -5px;
        }
      }
    }
    p.reference {
      max-width: 1140px;
      color: $white;
      margin: 40px auto 10px;
    }
    .subpage-footer-cta {
      padding-top: 25px;
      padding-bottom: 15px;
      @media screen and (max-width: 1210px) {
        padding-top: 10px;
        padding-bottom: 10px;
        p {
          margin-bottom: 5px;
        }
      }
      > p:first-of-type {
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  &.available {
    .content-snippet {
      border-radius: 0 0 0 32px;
      @media screen and (max-width: 1210px) {
        padding-top: 70px;
      }
    }
    .subpage-footer-cta {
      max-width: 580px;
    }
    .big-number-wrap {
      p { 
        text-align: left!important;
        position: relative;
        left: 30px;
        @media screen and ( max-width: 768px) {
          left: 0;
        }
      }
      .no-dash {
        p {
          left: 15px
        }
      }
    }
  }
  &:first-of-type {
    margin-top: 0;
  }
  &.orange, &.green, &.yellow, &.purple {
    &::before {
      content: "";
      display: block;
      height: 100%;
      width: 100vw;
      top: 0;
      left: calc(-100vw + 1px);
      position: absolute;
    }
  }
  &.orange {
    background-color: $orange;
    &::before {
      background-color: $orange;
    }
  }
  &.green {
    background-color: $green;
    &::before {
      background-color: $green;
    }
    .subpage-footer-cta {
      p {
        color: $dark-green;
      }
    }
  }
  &.yellow {
    background-color: $yellow;
    &::before {
      background-color: $yellow;
    }
  }
  &.purple {
    background-color: $purple;
    &::before {
      background-color: $purple;
    }
  }
  div {
    border-radius: 0 0 0 32px;
    &.orange {
      background-color: $orange;
      a.button {
        background-color: $green;
        color: $white;
        @media screen and (max-width: 1210px) { 
          float: right;
          font-size: 18px;
          padding: 16px 45px 16px 26px;
        }
      }
    }
    &.green {
      background-color: $green;
    }
    &.yellow {
      background-color: $yellow;
      h2 {
        color: $dark-green
      }
      a.button {
        background-color: $purple;
        color: $white;
      }
    }
    &.purple {
      background-color: $purple;
      a.button {
        background-color: $orange;
        color: $white;
      }
    }
  }

  .subpage-title {
    position: absolute;
    width: calc(100% - 80px);
    top: -35px;
    right: 0;
    padding: 25px 40px 25px 40px;
    z-index: 1;
    @media screen and (max-width: 1210px) {
      width: calc(100% - 9px);
      left: 9px;
      padding: 20px 0px;
    }
    h2 {
      color: $white;
      text-align: left;
      width: 100%;
      margin: 0;
      @media screen and (max-width: 1210px) {
        margin: 0px 20px;
        width: unset;
      }
    }
    p {
      color: $white;
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      margin-top: 10px;
      @media screen and (max-width: 1210px) {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  h3.title {
    text-align: center;
    color: $white;
  }

  .row {
    text-align: center;
  }

  .subpage-content {
    position: relative;
    top: -25px;
    h3 { margin-top: 0; }
    .subpage-card-wrap {
      margin-top: 30px;
      gap: 0 10px;
      .column {
        background-color: #fff;
        min-height: 300px;
        padding: 100px 30px 30px 30px;
        @media screen and ( max-width: 768px) {
          min-height: 320px;
        }
      }
      .icon {
        width: 215px;
        height: 230px;
        border-radius: 0px 32px 0px 32px;
        background-color: $white;
        padding: 110px 30px 20px;
        position: relative;
        margin-top: 100px;
        margin-bottom: 80px;
        @media screen and ( max-width: 768px) {
          margin-left: 18px;
          margin-right: 18px;
        }
        p {
            font-size: 18px;
            line-height: 22px;
        }
        a.button {
          padding: 16px 40px 16px 30px;
          font-size: 16px;
          line-height: 18px;
        }
        &.icon-orange {
          &::before {
            content: "";
            position: absolute;
            top: -80px;
            left: calc(50% - 82.5px);
            display: block;
            width: 110px;
            height: 110px;
            padding: 20px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: 80px;
            background-position: center;
            border: 10px solid $orange;
            background-color: #fff;
          }
          &.antibody {
            &::before {
              background-image: url(../img/icons/antibody.svg);
            }
          }
          &.route {
            &::before {
              background-image: url(../img/icons/route.svg);
            }
          }
          &.subtract {
            margin-right: 3px;
            @media screen and ( max-width: 768px) {
              margin-right: 18px;
              margin-bottom: 0;
            }
            &::before {
              background-image: url(../img/icons/subtract.svg);
            }
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 18px;
            width: calc(100% + 15px);
            border-radius: 0px 32px 0px 0px;
          }
        }
        &.orange {
          &::before {
            background-image: url(../img/icons/droplet.svg);
            border-color: $orange;
          }
          &::after {
            background-color: $orange;
          }
        }
      }
    }
  }

  .subpage-footer-cta {
    background-color: $orange;
    padding: 40px 50px;
    width: 100%;
    position: absolute;
    right: 40px;
    bottom: -61px;
    border-right: 20px solid $dark-green;
    border-radius: 0px 0px 0px 32px!important;
    max-width: 1078px;
    @media screen and (max-width: 1210px) {
      border-right: 12px solid $dark-green;
      width: calc(100% - 120px);
      right: 15px;
      padding: 25px 10px 25px 25px;
      bottom: -79px;
    }
    .row {
      justify-content: center;
      align-items: center;
      .column {
        &:first-of-type {
          width: calc(100% - 250px);
          flex: calc(100% - 250px);
          flex-grow: 1;
          p { 
            text-align: right; margin-left: -10px; 
            @media screen and ( max-width: 768px) {
              text-align: left;
            }
          }
          flex-grow: 1;
          @media screen and (max-width: 1210px) { 
            flex: 100%;
            p {
              margin-top: 0px;
            }
          }
        }
        &:last-of-type {
          width: 250px;
          flex: 250px;
          text-align: left;
          flex-grow: 0;
          @media screen and (max-width: 1210px) { 
            flex: 100%;
          }
        }
      }
    }
    p {
      font-family: "Roboto Condensed", sans-serif;
      color: $white;
      display: inline-block;
      margin-right: 0px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      width: 100%;
      text-align: left;
      @media screen and (max-width: 1210px) { 
        font-size: 18px;
        line-height: 1.2em;
      }
    }
    a.button {
      margin: 0 0 0 auto;
    }
    &.green {
      border-color: $yellow;
    }
    &.purple {
      border-color: $orange;
    }
  }

  // Mirror the design on the even
  &:nth-of-type(even) {
    border-radius: 0px 0 0 32px;
    div {
      border-radius: 0 0 32px 0;
    }
    .subpage-title {
      left: 0px;
    }
    &.orange, &.green, &.yellow, &.purple {
      &::before {
        left: unset;
        right: calc(-100vw + 1px);
      }
    }
  }
}