@use 'variables' as *;

// Variables
$container-padding: 40px;
$column-padding: 15px;
$breakpoint-mobile: 768px;

// Container
.container {
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 $container-padding;
  @media screen and (max-width: 1210px) {
    padding: 0 18px;
  }
}

// Row
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 (-$column-padding);
}

// Columns
.column {
  flex: 1;
  padding: 0 $column-padding;
  box-sizing: border-box;

  // Responsive Columns
  @media (max-width: $breakpoint-mobile) {
    flex: 100%;
    max-width: 100%;
  }
}

// Mixin for column width
@mixin column-width($columns) {
  flex: 0 0 percentage($columns / 12);
  max-width: percentage($columns / 12);
}

sub, sup {
  font-size: 60%;
}

h1 {
  font-family: "Roboto Condensed", sans-serif;
  &.orange {
    color: $orange;
  }
  &.green {
    color: $green;
  }
  &.dark-green {
    color: $dark-green;
  }
  &.yellow {
    color: $yellow;
  }
  &.purple {
    color: $purple;
  }
}

h2 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  @media screen and (max-width: 1210px) {
    font-size: 24px;
    line-height: 28px;
  }
  &.orange {
    color: $orange;
  }
  &.green {
    color: $green;
  }
  &.dark-green {
    color: $dark-green;
  }
  &.yellow {
    color: $yellow;
  }
  &.purple {
    color: $purple;
  }
}

h3 {
  color: $white;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  @media screen and (max-width: 1210px) {
    font-size: 22px;
    line-height: 26px;
  }
  &.orange {
    color: $orange;
  }
  &.green {
    color: $green;
  }
  &.dark-green {
    color: $dark-green;
  }
  &.yellow {
    color: $yellow;
  }
  &.purple {
    color: $purple;
  }
}

h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; 
  &.orange {
    color: $orange;
  }
  &.green {
    color: $green;
  }
  &.dark-green {
    color: $dark-green;
  }
  &.yellow {
    color: $yellow;
  }
  &.purple {
    color: $purple;
  }
}

p {
  &.orange {
    color: $orange;
  }
  &.green {
    color: $green;
  }
  &.dark-green {
    color: $dark-green;
  }
  &.yellow {
    color: $yellow;
  }
  &.purple {
    color: $purple;
  }
  &.center {
    text-align: center!important;
  }
}

small {
  font-size: 14px;
  line-height: normal;
}

span {
  &.orange {
    color: $orange;
  }
  &.green {
    color: $green;
  }
  &.dark-green {
    color: $dark-green;
  }
  &.yellow {
    color: $yellow;
  }
  &.purple {
    color: $purple;
  }
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.big-number-wrap {
  text-align: center;
  align-items: center;
  p {
    margin-top: 2px;
  }
  .big-number {
    font-size: 102px;
    font-style: normal;
    font-weight: 700;
    line-height: 102px; /* 100% */
    letter-spacing: -5px;
    margin-bottom: 0;
    margin-top: 0;
    @media screen and (max-width: 1210px) {
      font-size: 50px;
      line-height: 60px;
      letter-spacing: 0;
    }
    &.orange {
      color: $orange;
    }
    &.green {
      color: $green;
    }
    &.yellow {
      color: $yellow;
    }
    &.purple {
      color: $purple;
    }
  }
  .column {
    &:nth-of-type(2) {
      display: block;
      border-right: 4px dashed $orange;
      @media screen and ( max-width: 768px) {
        border-right: unset;
        border-top: 4px dashed $orange;
        border-radius: 0;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
    }
    &:nth-of-type(3) {
      @media screen and ( max-width: 768px) {
        padding-top: 30px;
      }
    }
    &.no-dash {
      border-right: 0;
      @media screen and ( max-width: 768px) {
        margin-top: 30px;
        padding-top: 30px;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  p {
    text-align: center!important;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 5px;
  }
}

.content-snippet {
  background-color: $white;
  padding: 40px;
  border-radius: 0px 32px;
  @media screen and (max-width: 1210px) {
    padding: 20px;
  }
  h3 {
    color: $dark-green;
    text-align: left;
    margin-top: 0;
  }
  p {
    text-align: left;
  }
  .patients {
    h3 {
      text-align: center;
      margin: 0 auto 50px;
    }
  }
  ul {
    li {
      text-align: left;
    }
  }
}

.inline-icon, .inline-wrap {
  display: inline-block;
}

.chart-wrap {
  display: flex;
  gap: 25px;
  text-align: center;
  margin: 40px 0;
  margin-left: 150px;
  margin-right: 150px;
  @media screen and (max-width: 1210px) {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
  }
  .chart {
    width: 50%;
    border: 4px solid $purple;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(80,44,72,1) 50%);
    padding: 25px;
    @media screen and (max-width: 1210px) {
      width: 100%;
      padding: 20px;
    }
    h3 { 
      text-align: center;
    }
    p {
      text-align: center;
      color: $white;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      @media screen and (max-width: 1210px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
    &:first-child {
      border-radius: 32px 0px;
    }
    &:last-of-type {
      border-radius: 0px 32px;
      
    }
  }
}

.foot-callout {
  max-width: 1005px;
  margin: 50px auto;
  padding: 25px 40px 25px 170px;
  position: relative;
  background-color: rgba(255,255,255, 0.65);
  border-radius: 60px 32px 32px 60px;
  justify-content: center;
  align-items: center;
  &.stc-kit {
    padding: 40px 50px;
    @media screen and (max-width: 1210px) {
      padding: 20px;
      margin: 180px 18px 18px;
      border-radius: 32px;
      text-align: center;
    }
    &::before {
      top: -15px;
      left: -65px;
      @media screen and (max-width: 1210px) {
        top: -150px;
        left: calc(50% - 75px)
      }
    }
  }
  strong {
    display: block;
    margin-bottom: 16px;
  }
  .column {
    &:first-of-type {
      width: 60%;
      flex: 60%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  .centered {
    margin-top: 15px;
  }
  p {
    font-size: 24px;
    font-style: normal;
    line-height: 28px;
    color: $dark-green;
    margin: 0;
    font-family: "Roboto Condensed", sans-serif;
  }
  a.button {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 21px 40px 21px 40px;
  }
  ul {
    display: block;
    margin: 0;
    padding-left: 0;
    li {
      list-style: none;
      display: inline-block;
      text-align: left;
      margin-right: 10px;
      font-size: 24px;
      a {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        text-decoration: underline;
        color: $dark-green;
        font-family: "Roboto Condensed", sans-serif;
      }
    }
  }
  &::before {
    content: "";
    width: 150px;
    height: 150px;
    position: absolute;
    top: -35px;
    left: 0px;
    background-image: url(../img/icons/germ.svg);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px;
    background-color: $purple;
    border-radius: 50%;
  }
  &.germ-yellow {
    border-radius: 0px 32px 32px 0px;
    background: linear-gradient(90deg, rgba(0, 87, 79, 0.15) 0%, rgba(255, 208, 0, 0.15) 100%);
    max-width: 960px;
    padding-left: 120px;
    @media screen and (max-width: 1210px) {
      padding-left: 0;
      margin-top: 220px;
      padding: 10px;
      background: $white;
    }
    strong {
      margin-bottom: 0;
      @media screen and (max-width: 1210px) {
        text-align: center;
      }
    }
    &::before {
      background-color: $yellow;
      background-image: url(../img/icons/green-germ.svg);
      left: -30px;
      @media screen and (max-width: 1210px) {
        left: calc(50% - 85px);
        top: -180px;
      }
    }
  }
  &.no-button {
    border-radius: 160px 32px 32px 160px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding: 30px 32px 32px 160px;
    @media screen and (max-width: 1210px) {
      padding: 20px;
      border-radius: 32px;
      margin: 250px 18px 18px;
    }
    .row {
      .column {
        justify-content: left;
        align-items: baseline;
      }
    }
    &::before {
      top: -5px;
      left: -30px;
      @media screen and (max-width: 1210px) {
        top: -180px;
        left: calc(50% - 75px);
      }
    }
  }
  &.links {
    max-width: 750px;
    margin: 100px auto;
    @media screen and (max-width: 1210px) {
      padding: 20px;
      text-align: center;
      margin: 300px 20px 100px;
      border-radius: 32px;
      li {
        margin-bottom: 20px;
      }
    }
    &::before {
      top: -30px;
      @media screen and (max-width: 1210px) {
        top: -200px;
        left: calc(50% - 85px);
      }
    }
  }
}

a.button {
  border-radius: 80px;
  background: $yellow;
  color: $dark-green;
  display: inline-flex;
  padding: 21px 60px 21px 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 1210px) {
    padding: 10px 35px 10px 21px;
    font-size: 12px;
  }
  &::after {
    content: "";
    position: absolute;
    right: 30px;
    top: 15px;
    top: calc(50% - 6px);
    width: 12px;
    height: 12px;
    background-image: url(../img/shared/arrow-down-green.svg);
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    @media screen and (max-width: 1210px) {
      right: 15px;
    }
  }
  &.white-arrow {
    &::after {
      background-image: url(../img/shared/arrow.svg);
      transform: unset;
    }
  }

  &.orange {
    background-color: $orange;
    color: $white;
  }
  &.green {
    background-color: $green;
    color: $white;
  }
  &.dark-green {
    background-color: $dark-green;
    color: $white;
  }
  &.yellow {
    background-color: $yellow;
    color: $dark-green;
  }
  &.purple {
    background-color: $purple;
    color: $white;
  }
}

sup {
  line-height: 100%;
}

.overlay {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 100px;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
  opacity: 80%;
  @media screen and (max-width: 728px) {
    top: 100px
  }
  &.orange {
    background-color: #903E13;
  }
  &.green {
    background-color: #02221F;
  }
  &.dark-green {
    background-color: $dark-green;
  }
  &.yellow {
    background-color: #C2A00A;
  }
  &.purple {
    background-color: #2A1D27;
  }
}

// Gap
.gap-0 {
  gap: 0px!important;
}