@use 'variables' as *;

#cookies-modal {
    width: 100%;
    background-color: #E9E0E7;
    color: $dark-green;
    z-index: 2;
    display: none;
    padding-top: 25px;
    padding-bottom: 25px;

    @media screen and (max-width: 1210px) {
        padding: 0px 10px;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    h3 {
        margin-bottom: 5px;
        color: $dark-green;
        margin-top: 0;
    }

    p {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .container {
        display: flex;
        align-items: center;

        .column {
            @media screen and (min-width: 1211px) {
                &:first-of-type {
                    width: 70%;
                    flex: 70%;
                    margin-left: 0;
                    padding-left: 0;
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }

        @media screen and (max-width: 1210px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .footer-column-button {
            min-width: 420px;
            @media screen and (max-width: 1210px) {
                margin-top: 10px;
            }
        }
    }
}

.cookies {
    position: fixed;
    bottom: 0px;
    width: 100vw;

    .container {
        max-width: 1400px;
        margin: 0px auto;
        width: 100%;
    }
}

body.modal-active {
    .cookies {
        display: none;
    }
}