@use 'variables' as *;

html, body {
	font-family: Roboto, sans-serif;
	font-weight: 400;
	line-height: 1.22em;
	font-style: normal;
	color: $body;
	overflow-x: hidden;
}

body {
	margin-top: 130px;
	background-color: $white;
	@media screen and (max-width: 1210px) {
		margin-top: 102px;
	}
	&.thank-you, &.error {
		.container {
			min-height: calc(100vh - 200px);
			@media screen and (max-width: 1210px) {
				min-height: unset;
			}
		}
		@media screen and (max-width: 1210px) {
			a.button {
				margin-top: 20px;
			}
		}
		footer {
			.container {
				min-height: unset;
			}
		}
	}
	&.sign-up, &.thank-you, &.error, &.sitemap {
		margin-top: 0;
		#hero {
			min-height: unset;
			background-color: unset;
			.hero-box {
				top: 250px;
				z-index: 2;
				@media screen and (max-width: 1210px) {
					top: 130px;
				}
			}
		}
		section.main {
			background: linear-gradient(90deg, rgba(0, 87, 79, 0.25) 0%, rgba(255, 208, 0, 0.25) 100%);
			min-height: 800px;
			position: relative;
			&::after {
				content: "";
				display: block;
				background: linear-gradient(90deg, rgba(0, 87, 79, 0.25) 0%, rgba(255, 208, 0, 0.25) 100%);
				height: 100px;
				position: absolute;
				bottom: -100px;
				width: 100%;
			}
		}
	}
	section.thank-you, section.error {
		.hero-box {
			top: 120px!important;
		}
		&::after {
			content: "";
			display: block;
			background: linear-gradient(90deg, rgba(0, 87, 79, 0.25) 0%, rgba(255, 208, 0, 0.25) 100%);
			height: 100px;
			position: absolute;
			bottom: -100px;
			width: 100%;
		}
	}
	&.sign-up {
		min-height: 1000px;
		section.main {
			&::after {
				display: none;
			}
		}
		.subpage-callout::before {
			display: none;
		}
		.subpage-callout {
			right: -40px;
			position: relative;
			border-radius: 0 0 0 32px;
			@media screen and (max-width: 1210px) {
				padding-top: 340px;
				right: 0;
				top: -460px;
			}
			&::after {
				content: "";
				display: block;
				height: 100%;
				width: 100vw;
				top: 0;
				right: calc(-100vw + 1px);
				position: absolute;
				background-color: $green;
			}
		}
		section#main {
			padding-bottom: 200px;
			background: linear-gradient(180deg, rgba(191,213,211,1) 0%, rgba(255,243,192,1) 70%, rgba(255,255,255,1) 75%);
			form {
				padding-top: 30px;
			}
		}
	}
	&.sitemap {
		.hero-box {
			h1 {
				margin-top: 0;
				margin-bottom: 0!important;
			}
		}
		.subpage-callout {
			@media screen and (max-width: 1210px) {
				width: 100%;
				padding: 150px 0 0;
			}
		}
		.subpage-content {
			.content-snippet {
				background-color: $green;
				margin-top: 240px;
				border-radius: 0 0 0 32px;
				right: -40px;
				top: -40px;
				position: relative;
				@media screen and (max-width: 1210px) {
					margin-top: 0;
					padding-top: 100px;
					right: 0;
				}
				&::after {
					content: "";
					display: block;
					height: 100%;
					width: 100vw;
					top: 0;
					right: calc(-100vw + 1px);
					position: absolute;
					background-color: $green;
				}
				ul {
					margin-top: 20px;
					margin-bottom: 0;
					li {
						list-style: none;
						margin-top: 10px;
						margin-bottom: 10px;
						color: $yellow;
						font-weight: 700;
						&.section {
							margin-top: 30px;
						}
						a { 
							color: $white;
							font-weight: 400;
							&.home {
								color: $yellow;
								font-weight: 700;
							}
						}
					}
					ul {
						li { color: $white; }
					}
				}
			}
		}
	}
	&.epidemiology, &.treating {
		#header-nav {
			#nav {
				li.active {
					p.nav-label {
						color: $purple;
					}
				}
			}
		}
		.subpage-callout .subpage-footer-cta {
			border-color: $green;
		}
	}
	&.discerning, &.mitigating {
		.subpage-callout {
			.subpage-footer-cta {
				border-color: $purple;
			}
			tr:first-of-type {
				th {
					padding-top: 20px;
					padding-bottom: 20px;
				}
			}
		}
	}
	&.mitigating {
		.subpage-callout {
			.subpage-content .content-snippet {
				border-radius: 0px 0 0px 32px!important;
			}
			.subpage-footer-cta {
				border-radius: 0px 0 0px 32px;
			}
		}
	}
	&.discerning {
		.subpage-callout {
			tr:first-of-type {
				th {
				 	text-transform: none;
				}
			}
			.subpage-footer-cta {
				border-radius: 0px 16px 0px 32px !important;
			}
		}
	}
}

header {
	height: 140px;
	&.scrolled {
		position: fixed;
	}
}

.eyebrow {
	font-size: 16px;
	font-weight: 900;
	line-height: 1.25em;
	letter-spacing: 0.25em;
	text-transform: uppercase;
	@media screen and ( max-width: 768px) {
		font-size: 12px;
		max-width: 285px;
	}
}

.subhead {
	font-size: 24px;
	line-height: 1.16em;
}

p {
	font-family: Roboto, sans-serif;
	font-size: 18px;
	line-height: 22px;
	font-style: normal;
	font-weight: 400;
	@media screen and (max-width: 1210px) {
		font-size: 16px;
		line-height: 18px;
	}
	.callout-cta {
		display: inline-block;
		color: $white;
		border-radius: 80px;
		padding: 15px 45px 15px 30px;
		background-color: $dark-green;
		width: max-content;
		font-size: 16px;
		background-image: url(../img/shared/arrow.svg);
		background-repeat: no-repeat;
		background-position: right 28px center;
	}
}

.references-wrap {
	padding: 60px 40px;
	width: calc(100% - 80px);
	@media screen and (max-width: 1210px) {
		padding: 40px 0;
		width: calc(100% - 36px);
	}
	&.tdm {
		padding: 10px 0 50px;
		p { background-color: transparent; }
	}
	.references {
		font-size: 12px;
		line-height: 14px;
		background-color: $white;
		margin-top: 0px!important;
		margin-bottom: 4px!important;
		@media screen and (max-width: 1210px) {
			word-wrap: break-word;
		}
	}
}

.reference {
	font-size: 12px;
	margin: 40px 0 0;
	@media screen and (max-width: 1210px) {
		margin-top: 20px
	}
}

.error #sign-up, .sign-up #sign-up, .sitemap #sign-up, .thank-you #sign-up {
	display: none!important;
}

a {
	cursor: pointer;
}
