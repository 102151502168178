@use 'variables' as *;

#header-nav {
    font-family: "Roboto Condensed", sans-serif;
    max-width: 715px;
    width: 100%;
    position: absolute;
    right: 95px;
    top: 75px;
    font-size: 18px;
    line-height: 1.22em;
    z-index: 101;
    @media screen and (max-width: 1210px) {
        display: block;
        right: 0;
        top: 0;
    }
    #nav {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
        padding-left: 0px;
        @media screen and (max-width: 1210px) {
            display: none;
        }
        .nav-label {
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 18px;
            line-height: 22px;
        }
        a, p {
            text-decoration: none;
            color: $dark-green;
        }
        @media screen and (min-width: 1211px) {
            li {
                position: relative;
                &.active::before {
                    content: "";
                    display: block;
                    width: calc(100% + 20px);
                    height: 5px;
                    position: absolute;
                    bottom: 2px;
                    border-radius: 0 0 6px 6px;
                }
            }
            li.orange {
                &:hover p:after {
                    background-image: url(../img/shared/arrow-up-orange.svg);
                }
                p {
                    height: 47px;
                    position: relative;
                    &:hover {
                        color: $orange;
                        font-weight: 700;
                    }
                }
                a { 
                    color: $dark-green;
                }
                li, li:last-of-type {
                    border-color: $orange
                }
                &.active::before {
                    background-color: $orange;
                }
            }
            li.green {
                &:hover p:after {
                    background-image: url(../img/shared/arrow-up-green.svg);
                }
                p {
                    height: 47px;
                    &:hover {
                        color: $green;
                        font-weight: 700;
                    }
                }
                a { 
                    color: $purple;
                }
                li, li:last-of-type {
                    border-color: $green
                }
                &.active::before {
                    background-color: $green;
                }
            }
            li.yellow {
                &:hover p:after {
                    background-image: url(../img/shared/arrow-up-yellow.svg);
                }
                p {
                    height: 47px;
                    &:hover {
                        color: $dark-green;
                        font-weight: 700;
                    }
                }
                &.active::before {
                    background-color: $dark-green;
                }
                a { 
                    color: $orange;
                }
                li, li:last-of-type {
                    border-color: $yellow
                }
            }
            li.purple {
                &:hover p:after {
                    background-image: url(../img/shared/arrow-up-purple.svg);
                }
                p {
                    height: 47px;
                    &:hover {
                        color: $purple;
                        font-weight: 600;
                    }
                }
                a { 
                    color: $green;
                }
                li, li:last-of-type {
                    border-color: $purple
                }
                &.active::before {
                    background-color: $purple;
                }
            }
            li.purple-and-yellow {
                p {
                    height: 47px;
                    &:hover {
                        color: $dark-green;
                        font-weight: 700;
                    }
                }
                &.active::before {
                    background-color: $green;
                }
                a { 
                    color: $green;
                }
                li, li:last-of-type {
                    border-color: $dark-green
                }
            }
        }
        li {
            list-style: none;
            text-align: center;
        }
        .nav-sub {
            display: none;
            font-weight: normal;
            flex-direction: column;
            background-color: $white;
            padding-left: 0px;
            position: absolute;
            width: max-content;
            top: 65px;
            width: 160px;
            font-size: 15px;
            line-height: 1.13em;
            margin-left: -20px;
            border-radius: 0px 0px 6px 6px;
            box-shadow: 0px 6px 6px rgba(44, 56, 48, 0.35);
            li {
                text-align: left;
                padding: 10px;
                border-top: 1px solid $dark-green;
            }
            li:last-of-type {
                border-bottom: 8px solid $dark-green;
                border-radius: 0px 0px 6px 6px;
                clip-path: border-box;
            }
        }
        .nav-item {
            cursor: pointer;
            p {
                margin-top: 0px;
                margin-bottom: 25px;
                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 43px;
                    background-image: url(../img/shared/arrow-down-green.svg);
                    background-position: bottom right;
                    background-repeat: no-repeat;
                    position: relative;
                    bottom: 50px;
                    right: -20px;
                }
            }
        }
        .nav-item:hover, .nav-item.opened {
            > a, > p { 
                color: #502C48;
            }
            p:after {
                background-image: url(../img/shared/arrow-up-green.svg);
            } 
            @media screen and (min-width: 1211px) {
                .nav-sub {
                    display: block;
                }
            }

        }
        .nav-item {
            &.active {
                p { font-weight: 700; }
            }
            a:hover {
                font-weight: 700;
            }
        }

        .nav-item {
            a.active {
                font-weight: 700;
            }
        }
    }
    @media screen and (max-width: 1210px) {
        #nav.opened {
            display: block;
            width: calc(100% - 40px);
            position: absolute;
            top: 100px;
            left: 20px;
            margin-top: 50px;
            overflow-y: scroll;
            .nav-label {
                padding: 0;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                height: 20px;
                margin-left: 5px;
                br {
                    display: none;
                }
            }
            .nav-item {
                padding: 20px 5px;
                margin-bottom: 15px;
                cursor: pointer;
                text-align: left;
                box-shadow: 0 6px 6px rgba(44, 56, 48, 0.85);
                p {
                    margin-top: 0px;
                    margin-bottom: 25px;
                    position: relative;
                    height: 20px;
                    margin-bottom: 0;
                    &:after {
                        content: "";
                        display: block;
                        width: 25px;
                        height: 25px;
                        background-image: url(../img/shared/arrow.svg);
                        background-position: bottom right;
                        background-repeat: no-repeat;
                        position: absolute;
                        bottom: 5px;
                        right: 30px;
                        transform: rotate(90deg);
                    }
                }
                &.opened {
                    ul {
                        display: block;
                        position: relative;
                        left: 15px;
                        right: 0;
                        top: 20px;
                        width: calc(100% + 10px);
                    }
                }
                &.orange {
                    background-color: $orange;
                    li:last-of-type {
                        border-color: $orange;
                    }
                    p { color: $white; }
                    a {
                        color: $dark-green;
                    }
                }
                &.green {
                    background-color: $green;
                    li:last-of-type {
                        border-color: $green;
                    }
                    p { color: $white; }
                    a {
                        color: $dark-green;
                    }
                }
                &.yellow {
                    background-color: $yellow;
                    li:last-of-type {
                        border-color: $yellow;
                    }
                    p { color: $dark-green; &::after { background-image: url(../img/shared/arrow-green.svg); } }
                    a {
                        color: $orange;
                    }
                }
                &.purple {
                    background-color: $purple;
                    p { color: $white!important; }
                    a {
                        color: $dark-green;
                    }
                    li:last-of-type {
                        border-color: $purple;
                    }
                }
                &.purple-and-yellow {
                    li:last-of-type {
                        border-color: $yellow;
                    }
                }
            }
        }
    }
    
    .mobile-nav {
        @media screen and (max-width: 1210px) {
            border-radius: 8px 0px;
            background: #FFF;
            box-shadow: 0px 3px 3px 0px rgba(44, 56, 48, 0.35);
            padding: 5px;
            display: block;
            position: absolute;
            cursor: pointer;
            top: 55px;
            right: 10px;
            width: 45px;
            height: 35px;
            background-image: url(../img/icons/nav.svg);
            background-repeat: no-repeat;
            background-size: 90%;
            background-repeat: no-repeat;
            padding-top: 10px;
            text-align: center;
            font-weight: 300;
            z-index: 999999;
            background-position: center;
            &.opened {
                font-size: 2.5em;
                line-height: 0.6em;
                padding-top: 15px;
                background-image: unset;
                background-color: $green;
                color: $white;
            }
            &.scrolled {
                position: fixed;
                top: 60spx
            }
        }
    }        
}

@media screen and (max-width: 1210px) {
    header {
        &.green {
            #header-nav .mobile-nav.opened {
                background-color: $orange;
            }
        }
        &.orange {
            #header-nav .mobile-nav.opened {
                background-color: $purple;
            }
        }
        &.yellow {
            #header-nav .mobile-nav.opened {
                background-color: $green;
            }
        }
        &.purple {
            #header-nav .mobile-nav.opened {
                background-color: $yellow;
            }
        }
    }
}